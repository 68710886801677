<template>
  <div class="notifications-card" @click="openNotification()">
    <div v-if="notificationsStatus" class="notifications-card__notification-status"></div>
    <a-checkbox :checked="isChecked" @click.stop="notificationCheck()" />
    <div class="notifications-card__user-img">
      <img :src="articleNotifications.profile_picture" alt="" />
    </div>
    <div class="notifications-card__user-details">
      <h1 class="user-name">{{ articleNotifications.full_name }}</h1>
      <a class="notification-title">
        {{ articleNotifications.verb ?? ''}}
      </a>
      <span class="notification-title-message">
        {{ articleNotifications?.data?.data.message ?? ''}}
      </span>
      <p class="time">{{ articleNotifications.human_time }}</p>
      <!-- <p>{{articleNotifications?.data}}</p> -->
      <div
      v-if="articleNotifications?.data && articleNotifications?.data?.data?.is_responded === false && !typesDoNotShowButton.includes(articleNotifications?.data?.data?.type)"
      class="notifications-card__user-details--buttons">
        <a-button
          :loading="onInvitationAccept"
          :class="{ 'disable-btn': onInvitationReject }"
          @click.stop="acceptandRejectCategory(articleNotifications,'accepted')"
          class="accept-btn"
          >Accept
        </a-button>
        <a-button
        :loading="onInvitationReject"
        :class="{ 'disable-btn': onInvitationAccept }"
        @click.stop="acceptandRejectCategory(articleNotifications,'rejected')"
        class="reject-btn">Decline</a-button>
      </div>
    </div>
    <div class="notifications-card__dropdown">
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link" @click.stop>
          <i class="icon icon-vertical_three-dots"></i>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <a @click="markNotificationAsRead()">Mark as read</a>
            </a-menu-item>
            <a-menu-item key="1" @click="notifiDelete(articleNotifications.id)">
              <a class="remove-btn">Remove</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
  
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  emits: ["close-drawer","selectedNotification"],
  data() {
    return {
      userProfile: [],
      notificationsStatus: true,
      interestList: [],
      oldCategories: [],
      onInvitationReject: false,
      onInvitationAccept: false,
      showMessageModal: false,
      message: {},
      checked: false,
      typesDoNotShowButton: ['request-accept', 'comment']
    };
  },
  props: {
    articleNotifications: Object,
    ratings: Object,
    aggregatedRating: Object,
    status: Boolean,
    checkedNotificationIds: Array,
    notificationId: Number,
  },
  computed: {
    ...mapGetters('auth',['getUserId']),
    searchTopic() {
      const topic = this.$store.getters["discover/getSearchedTopic"];
      return  topic === "" ? "Discover-world-news" : topic.replace(/\s+/g, '-');
    },
    isChecked() {
      return this.checkedNotificationIds.includes(this.articleNotifications.id);
    },
   },
  async mounted() {
    this.notificationsStatus = this.status;
    // if(this.articleNotifications.data.data.type == 'interests'){
    //    this.interestInvitation();
    // }
  },
  methods: {
    ...mapActions('profile',['deleteNotification']),
    async getArticle() {
      window.scrollTo(0,0);
      const obj = this.articleNotifications.data.data;
      this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
      this.$router.push(`/discover/article/${this.searchTopic}/${obj.id}`);
      this.$emit('close-drawer');
    },
    async openNotification() {
      if (this.articleNotifications.data.data.type == "interests") {
        this.$emit('close-drawer');
        }
      else if (this.articleNotifications.data.data.type === "share-article") {
        this.getArticle(this.articleNotifications.target_object_id)
        this.updateNotificationsCount()
      }
    },
    async updateNotificationsCount(){
      try{
        const payload = {
          unread: false,
          id: this.notificationId,
        };
        const response = await this.$store.dispatch("notification/updateNotificationsAlert", payload);
        if (response.status == 200) {
        this.notificationsStatus = false;
        await this.$store.dispatch("notification/fetchNotificationCount");
        }
      } catch(err) {
        console.log(err)
      }
    },
    async fetchComments(articleId) {
      await this.$store.dispatch(
        "discover/fetchDiscoverArticleComments",
        {'article_id':articleId}
      );
    },
    async acceptandRejectCategory(notification,action) {
      if (action === 'accepted') {
        this.onInvitationAccept = true;
      } else {
        this.onInvitationReject = true;
      }
        try {
        const payload={
          notific_id: notification.id,
          status: action,
        }
        await this.$store.dispatch("profile/acceptorReject", payload);
        this.updateNotificationsCount()
        await this.$store.dispatch("profile/getUserTopicsWithOrdering");
      } catch (error) {
        console.log(error);
      } finally{
        this.onInvitationAccept = false;
        this.onInvitationReject = false;
      }

    },
    async acceptandRejectInvitaion(action, id) {
      this.onInvitationAccept = (action === 'accepted');
      this.onInvitationReject = (action !== 'accepted');
      const payload = {
        notification_id: this.articleNotifications.id,
        action: action,
        saved_article_folder: id
      }
      try {
        const response = await this.$store.dispatch("profile/acceptOrRejectSaveArticleInvite", payload);
        if(response.status == 200 && action == 'accepted'){
          this.onInvitationAccept = false;
          this.showMessageModal = true;
          this.message = {
          title: "Folder saved! It is now part of your shared Workspace",
          type: "success",
          };
          // this.$emit('close-drawer');
        }
      } catch (err) {
        console.log(err);
      }
      this.updateNotificationsCount();
      // await this.$store.dispatch("notification/fetchNotifications");
      this.onInvitationReject = false;
    },
    async acceptandRejectFolder(action, id) {
      this.onInvitationAccept = (action === 'accepted');
      this.onInvitationReject = (action !== 'accepted');
      const payload = {
        notification_id: this.articleNotifications.id,
        status: action,
      }
      try {
        const response = await this.$store.dispatch("folders/acceptOrRejectGlocalFoldersInvitation", payload);
        if(response.status == 200 && action == 'accepted'){
          this.onInvitationAccept = false;
          this.showMessageModal = true;
          this.message = {
          title: "Folder saved! It is now part of your shared Workspace",
          type: "success",
          };
          // this.$emit('close-drawer');
        }
      } catch (err) {
        console.log(err);
      }
      this.updateNotificationsCount();
      this.onInvitationReject = false;
    },
     async notifiDelete(notificId){
      try {
        const payload={
          id:notificId
        }
       const res = await this.$store.dispatch("notification/deleteNotification",payload);
       if(res.status===200){
        this.showMessageModal = true;
          this.message = {
          title: "Notification removed successfully.",
          type: "success",
          };
       }
      } catch (error) {
        console.log(error);
      }
    },
    async markNotificationAsRead() { 
      //marking individual notification as read
      await this.updateNotificationsCount()
    },
    notificationCheck() {
      this.$emit('selectedNotification', this.articleNotifications.id)
    }
  },
  watch: {
      status: {
        handler(val) { 
          this.notificationsStatus = val
        },
        immediate:true
      }
    }
};
</script>
  
<style lang="scss">
.notifications-card {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 2rem 4rem !important;
  border-bottom: 0.2rem solid #aeaeae3a;
  cursor: pointer;
  transition: all 0.2s;
  background: #ffffff38;
  &__user-img {
    width: 5rem;
    height: auto;
    margin-right: 1rem !important;
    border-radius: 100%;
    img {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      object-fit: cover;
      background-color: rgba(216, 216, 216, 0.274);
    }
  }
  &__user-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    line-height: 1rem;
    .user-name {
      font-size: 1.8rem;
      font-family: $font-primary-bold;
      line-height: 2.2rem;
      margin-bottom: 0.2rem;
    }
    .notification-title,
    .notification-title-message {
      font-size: 1.6rem;
      font-family: $font-primary;
      line-height: normal;
      opacity: 0.6;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: fit-content;
      color: $color-black;
      text-decoration: underline;
    }
    .notification-title-message{
      font-family: $font-primary;
      opacity: 1;
      font-style: italic;
      text-decoration: none;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1; 
      -webkit-box-orient: vertical;
      margin-top: 0.7rem
    }
    .time {
      font-size: 1.2rem;
      font-family: $font-primary;
      opacity: 0.8;
      line-height: normal;
      margin: 0.8rem 0 0 !important;
    }
    &--buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      margin: 2rem 0 1rem !important;
      .ant-btn {
        border: none;
        height: 3.5rem;
        font-size: 1.3rem;
        font-family: $font-primary-medium;
        width: 14rem;
        border-radius: 0;
        span {
          line-height: normal;
        }
        .anticon {
          .anticon-spin {
            width: 2rem;
          }
        }
      }
      .accept-btn {
        background-color: $color-primary;
        color: $color-white;
        margin-right: 1rem;
        border: 1px solid $color-primary;
        &:hover,
        &:focus {
          background-color: $color-primary;
          color: $color-white;
          border: none;
        }
      }
      .reject-btn {
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;
        &:hover,
        &:focus {
          background-color: $color-white;
          color: $color-black;
        }
      }
      .disable-btn {
        pointer-events: none;
        background-color: gray;
        opacity: 0.5;
        color: $color-black;
      }
    }
  }
  &__dropdown {
    .ant-dropdown-link {
      color: $color-black;
      width: 1rem;
      .icon {
        font-size: 1.5rem;
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-left: 0;
    font-size: 1.6rem;
    font-family: $font-secondary;
    width: fit-content;
    margin-bottom: 0;
    line-height: 2rem;
    margin-right: 1.5rem;

    .ant-checkbox {
      .ant-checkbox-inner {
        border: 0.18rem solid rgba(128, 128, 128, 0.377);
        height: 18px;
        width: 18px;
        border-radius: 0;
      }
      .ant-checkbox-input {
        width: fit-content;
      }
    }
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $color-primary;
        border-color: $color-primary;
        height: 18px;
        width: 18px;
        border-radius: 0;
      }
    }
  }
  &__notification-status {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: $color-primary;
    position: absolute;
    left: 1.5rem;
    top: 2.1rem;
  }

  &:hover {
    background-color: rgba(216, 216, 216, 0.274);
  }
}
</style>
  