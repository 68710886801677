<template>
  <a-drawer
    placement="right"
    :closable="true"
    :visible="drawerVisibility"
    wrapClassName="portfolio-drawer"
    width="46.5rem"
    title="My Portfolio"
    @close="$emit('close-drawer')"
  >
    <div class="portfolio-drawer-container">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1">
          <template #tab>
            <span class="tabs-name">Shared</span>
          </template>
          <div
            class="message-results-not-found"
            v-if="sharedArticles.length == 0 && !searchingShareArticles"
          >
            <p>No shared articles</p>
          </div>
          <div v-if="searchingShareArticles">
            <a-skeleton
              v-for="index in 5"
              :key="index"
              class="portfolio-card-skeleton"
              active
              :paragraph="{
                rows: 3,
              }"
            />
          </div>
          <portfolio-article-card
            v-else
            v-for="article in sharedArticles"
            :key="article.id"
            :title="article.article.title"
            :image="article.article.image"
            :source="article.article.source"
            :date="article.article.pub_date"
            :id="article.article.id"
            :authorName="article.article.authors"
            :sourceLogo="article.article.logo"
          ></portfolio-article-card>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <span class="tabs-name">
              <span>Rated</span>
            </span>
          </template>
          <div
            v-if="ratedArticles.length == 0 && !searchingRatedArticles"
            class="message-results-not-found"
          >
            <p>No shared articles</p>
          </div>
          <div v-if="searchingRatedArticles">
            <a-skeleton
              v-for="index in 5"
              :key="index"
              class="portfolio-card-skeleton"
              active
              :paragraph="{
                rows: 3,
              }"
            />
          </div>
          <portfolio-article-card
            v-else
            v-for="article in ratedArticles"
            :key="article.id"
            :title="article.title"
            :image="article.image"
            :source="article.source"
            :date="article.pub_date"
            :id="article.id"
            :authorName="article.authors"
            :sourceLogo="article.logo"
          ></portfolio-article-card>
        </a-tab-pane>
        <a-tab-pane key="3">
          <template #tab>
            <span class="tabs-name">
              <span>Collection</span>
            </span>
          </template>
          
          <div v-if="searchingCollectionsFolders">
            <a-skeleton
              v-for="index in 5"
              :key="index"
              class="portfolio-card-skeleton"
              active
              :paragraph="{
                rows: 3,
              }"
            />
          </div>
          <saved-article-collections
            v-else
            :skeletonLoading="searchingCollectionsFolders"
          > </saved-article-collections>
        </a-tab-pane>
      </a-tabs>
      <div @click="redirectToPortfolio()" class="portfolio-drawer-container__footer">
        <h1>
          View full portfolio
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_570_4062"
              style="mask-type:alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="25"
              height="24"
            >
              <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_570_4062)">
              <path
                d="M16.675 13H4.5V11H16.675L11.075 5.4L12.5 4L20.5 12L12.5 20L11.075 18.6L16.675 13Z"
                fill="#3764FB"
              />
            </g>
          </svg>
        </h1>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import PortfolioArticleCard from "../BaseComponents/PortfolioArticleCard.vue";
import SavedArticleCollections from "../BaseComponents/PortfolioSavedArticlesCollection.vue";

export default {
  emits: ["close-drawer"],
  components: {
    PortfolioArticleCard,
    SavedArticleCollections,
  },
  props: {
    drawerVisibility: Boolean,
  },
  watch: {
    drawerVisibility(val) {
      if (val) {
        this.getShareArticle();
      }
    },
    activeKey(val){
      if(val === '1'){
        this.getShareArticle();
      }else if(val === '2'){
        this.getRatedArticle()
      }else {
        this.getArticlesFolders();
      }
    }
  },
  data() {
    return {
      activeKey: "1",
      searchingShareArticles: false,
      searchingRatedArticles: false,
      searchingCollectionsFolders: false,
      message: {},
      showMessageModal: false
    };
  },
  computed: {
    userId() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    sharedArticles() {
      return this.$store.getters["article/getShareArticle"];
    },
    ratedArticles() {
      return this.$store.getters["article/getRatedArticle"];
    },
    artilcesFolders() {
      return this.$store.getters["article/getArticlesFolders"];
    },
  },
  mounted(){
    this.emitter.on('created-save-folder', (value)=> {
      this.showMessageModal = true;
      this.message = {
        title: value.title,
        type: value.type,
      };

    })
  },
  methods: {
    async getShareArticle() {
      this.searchingShareArticles = true;
      try {
        const payload = {
          user_id: this.userId,
          page: 1,
          page_size: 9,
        };
        await this.$store.dispatch("article/fetchUserShareArticle", payload);
      } catch (err) {
        console.log(err);
      }
      this.searchingShareArticles = false;
    },
    async getRatedArticle() {
      this.searchingRatedArticles = true;
      try {
        const payload = {
          user_id: this.userId,
          limit: 6,
          offset: 0,
        };
        await this.$store.dispatch("article/fetchUserRatedArticle", payload);
      } catch (err) {
        console.log(err);
      }
      this.searchingRatedArticles = false;
    },
    async getArticlesFolders() {
      this.searchingCollectionsFolders = true;
      try {
        const payload={
          user_id:this.$route.params.id,
        }
        await this.$store.dispatch("article/fetchSaveArticleFolder",payload);
      } catch (err) {
        console.log(err);
      }
      this.searchingCollectionsFolders = false;
    },
    redirectToPortfolio(){
      this.$router.push({
        path: `/user/${this.userId}/`,
        query: { tab: 'portfolio' },
      });
      this.emitter.emit("close-portfolio-drawer");
    }
  },
};
</script>

<style lang="scss">
.portfolio-drawer {
  @include reset-css;
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
          .portfolio-drawer-container {
            position: relative;
            height: 100vh;
            .ant-tabs {
              height: 100%;
              padding: 0 2.5rem;
              .ant-tabs-bar {
                border: none;
                margin-bottom: 2.6rem;
                .ant-tabs-nav-container {
                  padding-bottom: 2px;
                  .ant-tabs-tab-arrow-show {
                    display: none !important;
                  }
                  .ant-tabs-nav-wrap {
                    border: none;
                    .ant-tabs-nav-scroll {
                      border: none;
                      overflow-x: auto;
                      &::-webkit-scrollbar {
                        height: 0.3rem;
                      }
                      &::-webkit-scrollbar-track {
                        background-color: $color-white;
                        border-radius: 0.8rem;
                        height: 0.3rem;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $color-primary;
                        border-radius: 0.8rem;
                      }
                      @include respond(phone-x-small) {
                        &::-webkit-scrollbar {
                          display: none;
                        }
                      }
                      .ant-tabs-nav {
                        border: none;
                        padding-bottom: 0;
                        .ant-tabs-tab {
                          padding: 0.6rem 2rem;
                          border-radius: 100px;
                          font-size: 1.4rem;
                          border: 1px solid $color-dark-grey-5;
                          font-family: $font-primary;
                          color: #434343;
                          margin-right: 1.2rem;
                          margin-bottom: 0.2rem;
                        }
                        .ant-tabs-tab-active {
                          font-family: $font-primary-regular;
                          background-color: $color-dark-grey-5;
                          color: $color-black;
                          opacity: 1;
                        }
                        .ant-tabs-ink-bar {
                          display: none;
                        }
                      }
                      .ant-tabs-ink-bar {
                        display: none !important;
                      }
                    }
                  }
                }
              }
              .ant-tabs-content {
                .ant-tabs-tabpane {
                  overflow-y: auto;
                  height: calc(100vh - 25rem);
                  overflow-x: hidden;
                  .portfolio-card-skeleton {
                    margin-bottom: 1.5rem;
                    .ant-skeleton-content {
                      display: flex;
                      .ant-skeleton-title {
                        height: 6rem;
                        width: 6rem !important;
                        margin-top: 0;
                      }
                      .ant-skeleton-paragraph {
                        width: 100%;
                        margin-top: 0;
                        padding-left: 1rem;
                        li {
                          width: 100% !important;
                          margin-bottom: 1rem;
                          margin-top: 0;
                        }
                      }
                    }
                  }
                  .message-results-not-found {
                    padding: 2rem 0;
                    p {
                      font-size: 1.4rem;
                      font-family: $font-primary-medium;
                      opacity: 0.7;
                      text-align: center;
                    }
                  }
                }
                .ant-tabs-tabpane::-webkit-scrollbar-track {
                  background-color: transparent;
                }
                .ant-tabs-tabpane::-webkit-scrollbar {
                  width: 4px;
                  background-color: $color-white;
                  border-radius: 3rem;
                }
                .ant-tabs-tabpane::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 3rem;
                }
              }
            }
            &__footer {
              position: absolute;
              bottom: 8rem;
              background-color: $color-white;
              width: 100%;
              height: 8.2rem;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              border-top: 1px solid #cdcdcd;
              padding: 0 2.5rem;
              h1 {
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                color: $color-black;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-top: 0;
                opacity: 1;
              }
              svg {
                fill: $color-primary;
                margin-left: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
