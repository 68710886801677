<template>
  <a-modal
    :visible="visibleInvitationModal"
    @cancel="closevisibleInvitationModal"
    :footer="null"
    wrapClassName="invite-to-glocal-modal"
  >
    <div class="invite-glocal">
      <h2 class="invite-glocal__title">
        Invite to Connect
      </h2>
      <!-- <tagify-email
        placeholder="Invite Via Email"
        @fetchEmail="fetchedEmail"
        :isSubmitted="isSubmit"
      ></tagify-email> -->
      <div class="invite-glocal__body">
        <a-tabs v-model:activeKey="activeKey" class="invite-glocal__body--tabs">
          <a-tab-pane key="inviteViaGlocal">
            <template #tab>
              <div class="tab-name">
                <p>Find in Glocal</p>
              </div>
            </template>
            <TagifyContacts 
              module="inviteViaGlocal"
              :options="[]"
              @fetchSelection="updateConnectionList"
              placeholder="Enter name or email"
            />
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </a-tab-pane>
          <a-tab-pane key="inviteViaEmail">
            <template #tab>
              <div class="tab-name">
                <p>Invite via Email</p>
              </div>
            </template>
            <form class="form" @submit.prevent="inviteGlocal">
              <TagifyEmail @fetchEmail="updateEmailList" placeholder="Enter email"/>
            </form>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </a-tab-pane>
        </a-tabs>
        <a-button
          :loading="loading"
          class="invite-glocal__body--btn"
          @click="activeKey === 'inviteViaGlocal' ? inviteViaGlocal() : inviteViaEmail()"
        >
          Send
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex'
import TagifyContacts from '@/components/PdbComponents/TagifyContacts.vue';
import TagifyEmail from '@/components/PdbComponents/TagifyEmail.vue';

export default {
  props: {
    visibleModal: Boolean,
  },
  components:{
    TagifyContacts,
    TagifyEmail
  },
  watch: {
    visibleModal() {
      this.visibleInvitationModal = this.visibleModal;
    },
    email(value) {
      if (value) {
        this.FieldErr = false;
      }
    },
    activeKey:{
      handler(){
        this.email = null
        this.errorMessage = null
        this.connectSearchedList = []
        this.connectEmailList = []
        this.emitter.emit('clear-tagify-contacts')
        this.emitter.emit('clear-tagify-emails')
      },
    }
  },
  data() {
    return {
      visibleInvitationModal: false,
      showMessageModal: false,
      message: {},
      email: "",
      isSubmitted: false,
      FieldErr: false,
      loading: false,
      errorMessage: "",
      activeKey: 'inviteViaGlocal',
      connectSearchedList:[],
      connectEmailList: []
    };
  },
  computed: {
    isSubmit() {
      return this.isSubmitted;
    },
  },
  emits: ["closeModal"],
  methods: {
    ...mapActions({
      getUsersList: 'profile/getUsersList'
    }),
    closevisibleInvitationModal() {
      this.FieldErr = false;
      this.loading = false;
      this.errorMessage = "";
      this.email = "";
      this.glocalConnection = null
      this.connectSearchedList = []
      this.connectEmailList = []
      this.emitter.emit('clear-tagify-contacts')
      this.emitter.emit('clear-tagify-emails')
      this.$emit("closeModal");
    },
    async inviteViaEmail() {
      try {
        const userEmails = this.connectEmailList.map(email=>({email}))
        if (userEmails.length === 0) {
          this.errorMessage = "This field is required.";
          return false;
        }
          this.loading = true;

          await this.$store.dispatch("auth/inviteOnGlocal", userEmails);

          this.$showToast({message:'Email has been sent', type:'success'})
          this.closevisibleInvitationModal();
      } catch (err) {
        this.loading = false;
        this.FieldErr = true;
        this.errorMessage = err.response.data[0].email[0];
      }
      this.loading = false;
    },
    async handleSearch(e){
      if (e){
        this.fetching = true
      
      this.debounce(async ()=>{
          const that = this
          try{
            const response = await this.getUsersList({
            full_name: e,
          })
          this.connectSearchedList = response.data.map((val) => ({
                label: val.profile.full_name,
                value: val.profile.full_name,
                user: {...val.profile, id:val.id},
                key: val.email,
                icon: val.profile.profile_picture,
          }))
          }catch(err){
            console.log("ERROR")
          }finally{
             that.fetching = false
          }
         
      }, 1000)
    }else{
      this.glocalConnection = e
    }
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async inviteViaGlocal(){
      const usersToBeConnected = this.connectSearchedList.map(user=>({to_user:user.value, add_to_contributor: false}))
      
      if (usersToBeConnected.length == 0) {
          this.FieldErr = true;
          this.errorMessage = "This field is required.";
          return false;
      }
      else{
          this.loading = true;
          try{

            await this.$store.dispatch(
              "profile/sendUserRequest",
              usersToBeConnected
            );
            this.$showToast({message:'Invitation has been sent.', type:'success'})
            this.closevisibleInvitationModal();

          }catch(err){
            this.loading = false;
            this.errorMessage = err.response?.data?.non_field_errors[0];
          }finally{
            this.loading = false;
          }
        }
    },
    updateConnectionList(list){
      this.connectSearchedList = list
    },
    updateEmailList(list){
      this.connectEmailList = list
    }
  },
};
</script>

<style lang="scss">
.invite-to-glocal-modal {
  .ant-modal {
    width: 50rem !important;
    top: 15rem;
    .ant-modal-content {
      padding: 2.5rem;
      box-shadow: 0px 0px 94px #00000047;
      background: #f6f8ff;
      border-radius: 0;
      .ant-modal-close {
        margin: 2.3rem 2rem 0 0;
      }
      .ant-modal-header {
        display: none;
      }
      .ant-modal-body {
        .invite-glocal {
          &__title {
            font-size: 2.4rem;
            font-family: $font-secondary-bold;
            line-height: normal;
            margin-bottom: 2.5rem;
            padding-right: 2.5rem;
          }
          &__body {
            &--tabs {
              .ant-tabs-bar {
                margin: 0;
                border: none;
                .ant-tabs-nav-container {
                  .ant-tabs-tab-prev,
                  .ant-tabs-tab-next {
                    display: none !important;
                  }
                  .ant-tabs-nav-wrap {
                    .ant-tabs-nav-scroll {
                      overflow: hidden;
                      margin-bottom: 2.5rem;
                      .ant-tabs-nav {
                        width: 100%;
                        transform: none !important;
                        .ant-tabs-tab {
                          padding: 0.9rem 2rem;
                          border-radius: 100px;
                          border: 1px solid $color-dark-grey-5;
                          margin-right: 1.2rem;
                          margin-bottom: 0.2rem;
                          .tab-name {
                            display: flex;
                            align-items: center;
                            p {
                              font-size: 1.4rem;
                              line-height: normal;
                              font-family: $font-primary;
                              color: $color-dark-grey-6;
                              margin: 0;
                            }
                          }

                          &:last-child {
                            margin-right: 0;
                          }
                        }

                        .ant-tabs-tab-active {
                          background-color: $color-dark-grey-5;
                          text-shadow: none;
                          border-color: $color-dark-grey-5;
                          .tab-name {
                            p {
                              color: $color-black;
                              font-family: $font-primary-medium;
                            }
                          }
                        }
                        .ant-tabs-ink-bar {
                          display: none !important;
                        }
                      }
                      .ant-tabs-nav > div {
                        display: flex;
                      }
                    }
                  }
                }
              }
              .ant-tabs-top-content {
                .ant-tabs-tabpane {
                  height: max-content;
                  overflow: hidden;
                  .error {
                    color: red;
                    font-size: 1.4rem;
                    font-family: $font-primary;
                    margin: 0.5rem 0 0;
                    line-height: 1.8rem;
                  }
                }
              }
            }
            &--btn {
              font-size: 1.6rem;
              font-family: $font-primary-medium;
              width: 9rem;
              margin-top: 5rem;
              display: block;
              margin-left: auto;
              height: 4rem;
              border: none;
              background-color: $color-primary;
              color: $color-white;
              padding: 0;
              span {
                line-height: normal;
              }
              &:hover,
              &:active,
              &:focus {
                background-color: $color-primary;
                color: $color-white;
              }
              span {
                line-height: normal;
              }
              .anticon {
                .anticon-spin {
                  font-size: 2rem;
                  color: $color-primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
